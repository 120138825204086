import logo from './logo.svg';
import './App.css';
import { Button } from '@mui/material';
import lock from '../src/Assets/locking.png'
import swal from 'sweetalert';
function App() {
  const handlesubmit = () => {
    swal("PLEASE CONTACT TO NETCOM COMPUTER PVT LTD");
  }
  return (

    <div className='contain'>
      <div class='alert'>
        <div className='locked'>

          <img src={lock} /><br />

        </div>
        <h2 style={{ textAlign: 'center', color: '#E4025C' }}>Shivane Designing Temporarily Locked</h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button class='mt' variant="contained" color="success" onClick={handlesubmit}>
            Unlock
          </Button>
        </div>
      </div>
    </div>

  );
}

export default App;
